<template>
    <div class="aboutUs">
        <articleIndex bigImg="https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/ruanjianchanpin.png" title="软件产品" :leftList="leftList" @changeIndex="changeIndex" :action="showIndex" hideBreadCrumb="1">
            <slot slot="right">
                <div class="aboutusBox">
                    <!-- 标题、副标题、视频 -->
                    <div v-for="(item, index) in leftList" :key="index" class="video_warp">
                        <div class="title" v-if="showIndex == index">{{ item.text }}</div>
                        <div class="subtitle" v-if="showIndex == index">{{ item.subtitle }}</div>
                        <video width="718" height="403" controls preload="none" :poster="item.poster" v-if="showIndex == index && item.src">
                            <source :src="item.src" type="video/mp4" />
                        </video>
                    </div>
                    <div class="free_trial" @click="goIndex" v-if="leftList[showIndex].src">免费注册试用</div>
                    <!-- 描述 -->
                    <div v-show="showIndex == 0" class="summary">
                        <p class="spacingStyle">
                            云盈销™医药工业企业销售管理SAAS系统是大数据、云计算、云物联、云储存、人工智能等新兴技术在药品销售方面的具体应用，包括主页、渠道与区域人口经济、资料管理、客户管理、营销管理、销售流程、物流快递、销售档案、财务管理、统计分析、销售计划、库存管理、综合资讯、OA办公、组织架构、视频会议和系统设置等子系统。包含16个一级菜单，76个二级菜单和32个三级菜单，共320个权限对应320个管理细节，基本满足精细化管理的需求。
                        </p>
                        <br />
                        <p class="spacingStyle">系统提供电脑端、安卓APP、苹果APP、微信小程序4种登陆方式，满足不同应用场景，实现数字化移动办公。</p>
                        <br />
                        <p class="spacingStyle">为满足客户个性化需求，我们提供页面定制、数据定制、功能定制和业务逻辑定制服务，为客户定制属于自己的销售管理SAAS系统。</p>
                        <br />
                        <p class="spacingStyle">考虑到在试用过程中可能有些疑惑，系统在每个模块标题上均有标注说明，有些是操作指引和使用说明；有些是对应条款的医药行业法律法规小贴士；有些是药品营销的经典经验。</p>
                        <br />
                        <p class="spacingStyle">
                            创新驱动发展，信息创造未来。在传统经济逐步数字化、网络化、智能化的今天，流程数字化、管理数字化、办公数字化已经是现代企业的标配。希望通过我们的努力和创新，让更多医药工业企业拥有自己的数字化销售管理系统，融入数字经济、分享当代科技文明、分享数字经济带来的红利，提升企业科技形象和竞争力，创造价值。
                        </p>
                        <br />
                        <p class="spacingStyle">观看视频了解更多。</p>
                    </div>
                    <div v-show="showIndex == 1" class="summary">
                        <p class="spacingStyle">
                            渠道决定销量，市场资源数字化、网络化是数字时代的必然产物。以往医药工业企业对市场客户的归集，往往存在于市场人员的手机通讯录，或是简单excel表格中，市场客户数据零落在每个人、每个角落，经常会因为时间、人员交替而发生遗失，没有系统整合和梳理，没有充分利用市场客户数据来指导企业的销售工作。为此我们设计了渠道网络图，发挥其在市场营销中作战图、指挥图的作用。
                        </p>
                        <br />
                        <p class="spacingStyle">
                            渠道网络自动整合全国市场在用客户数据，搭建客户区位可视化市场平台。对各省、各市客户数量、客户分类、终端类型进行统计展示。渠道网络图以色彩饱和度区分客户数量，各区域客户数量对比更直观，市场大小，清晰可见。关联区域人口经济数据模块，展示各区域的渠道客户数、区域人口和经济数据。
                        </p>
                        <br />
                        <p class="spacingStyle">地级市渠道网络图展示市场客户分布，客户详细地址在列表中一目了然。支持切换卫星地图和路况地图。方便市场一线人员规划拜访客户线路，提高工作效率，少走弯路。</p>
                        <br />
                        <p class="spacingStyle">移动端支持一键导航，助力市场人员高效工作。</p>
                        <br />
                        <p class="spacingStyle">观看视频了解更多。</p>
                    </div>
                    <div v-show="showIndex == 2" class="summary">
                        <p class="spacingStyle">
                            互换或更新资质资料，是GMP、GSP对药品买卖双方的资格审查，是日常销售工作无法逾越的第一个环节；只有做好资质资料的互换工作，才能开展后续的业务。互换和更新资质资料是件既繁琐又容易出错的工作，因为每个客户所需要的资质资料和客户提供给企业的资质资料是不同的，
                            针对这些问题，我们设计出“资料管理”子系统。分公司资料和客户资料。
                        </p>
                        <br />
                        <p class="spacingStyle">
                            资料管理实现资质资料数字化、规范化管理。将资料分在用资料和历史资料进行分类管理，自动统计待完善资料，并提醒完善资料。支持图片、文档等多种格式资料的上传或下载，可通过手机扫描添加资质资料，在客户单位现场就能完成客户首营资料建档工作，以往需要几天才能成交的业务，现在只要在客户单位现场，花几分钟时间就能办完成交。
                        </p>
                        <br />
                        <p class="spacingStyle">大部分的资质资料都有时间限制即时效性，为确保资质资料能及时更新、有效，系统设置所有到期前30天的资料自动归集到近效期资料管理模块，方便集中处理，避免遗漏。</p>
                    </div>
                    <div v-show="showIndex == 3" class="summary">
                        <p class="spacingStyle">
                            区别于传统报单模式，系统实现销售流程数字化，彻底改变传统的办公模式，不受时间空间限制，支持手机操作各业务单元，销售人员可全天候移动办公。销售流程包含订单管理、起草合同、审核签字、合同盖章、放行审查、开单管理、退货管理和客户预警8个模块。其中客户预警是销售业务的辅助模块，助推销售工作。
                        </p>
                        <br />
                        <p class="spacingStyle">销售流程每个单元环节均设有短信提醒和推送功能，实现流程单元相互联动，确保流程畅通。同时系统设置全流程工作进度跟踪条，实时跟踪了解工作进度，杜绝工作怠惰，提高工作效率。使流程管理实现从制度管理到数字管理的飞跃。</p>
                        <br />
                        <p class="spacingStyle">客户是企业生存和发展的重要资源，客户是否达成交易以及交易频率是销售人员关注的焦点。系统设有客户预警模块，实现客户未订货周期和客户流失临界点提示，为销售人员及时维护客户关系，找准工作要点、难点、痛点提供帮助。</p>
                        <br />
                        <p class="spacingStyle">观看视频了解更多。</p>
                    </div>
                    <div v-show="showIndex == 4" class="summary">
                        <p class="spacingStyle">系统引入第三方物流数据，建立从开单到发货再到物流跟踪的全流程数字化管理。物流快递子系统共包括收件收货、地址簿、物流公司、发货管理、物流跟踪、物料管理、快递管理和破损管理8部分。</p>
                        <br />
                        <p class="spacingStyle">
                            为方便使用，系统自动复制好每条收货收件和地址簿的信息内容，一键复制，无需逐一选择复制内容。每条信息均有对应的二维码，方便信息读取。出库单规范地址是GSP规定的仓库地址，是经药监部门审查认可、符合药品存储设施条件的仓库地址，也是容易出问题的收货地址，系统已经关注并提出解决方案。
                        </p>
                        <br />
                        <p class="spacingStyle">
                            支持手机端发货，可手机扫描面单获取物流单号，提高单号录入准确度和效率。考虑到新冠疫情等特殊情况的发生，客户未签收货物前，可修改客户的收货地址或收货人等。受天气原因、自然灾害或客户自身原因影响，需要把货物暂时停放在物流网点等后续通知或直接退回。系统支持发起退回申请。
                        </p>
                        <br />
                        <p class="spacingStyle">系统已经对接第三方物流数据，不需要输入的物流公司、快递单号，可根据订单一键跟踪物流轨迹。同时系统自动生成发货信息和物流跟踪二维码，扫描二维码，就能获取发货信息和物流轨迹信息，方便信息的读取和传递，也可以和客户共享数字化信息的便捷。</p>
                        <br />
                        <p class="spacingStyle">观看视频了解更多。</p>
                    </div>
                    <div v-show="showIndex == 5" class="summary">
                        <p class="spacingStyle">以往业务人员想查账、对账免不了要打扰财务人员，是件麻烦事情。针对这个问题我们设计出数字化财务管理模块，包括往来账、回款管理、销售业绩表和费用结算表4个单元。</p>
                        <br />
                        <p class="spacingStyle">往来账是公司和客户之间的业务往来记录。可逐月查询，也可按客户名称、区域和指定月份查询。记录客户每月的期初应收款、本期增加、本期减少和期末应收款。点击联查可查看每笔销售或回款记录。使传统繁琐费时的查账、对账变得简单高效。</p>
                        <br />
                        <p class="spacingStyle">现金流是企业运营的基础，回款管理关注现金流管理，关注客户是否及时回款。包括未核销、已核销和流水账。PC端选择截止日期，自动生成相应的对账单，可下载获取电子版对账单。可查看客户当前的信用额度情况。</p>
                        <br />
                        <p class="spacingStyle">以往销售业绩由人工计算统计，效率低也容易出错。销售业绩表在开单时，同步生成相应的销售业绩数据，包括价差提成、业绩提成、税前、税后等数据。方便每位市场人员了解自己的业绩情况。支持按业务员、品种，时间段、级别设置不同的销售政策，促进不同市场的业务发展。</p>
                    </div>
                    <div v-show="showIndex == 6" class="summary">
                        <p class="spacingStyle">统计分析对系统内订单数据实时多维度统计，精准解读销售数据。让庞大繁杂的数据变得清晰明了。分订单统计表、销售明细表、销售汇总表、已核销订单统计表四个模块。</p>
                        <br />
                        <p class="spacingStyle">
                            订单统计表可筛选查看预订单、已开单、待发货、已发货、退回单等不同状态的订单数据。可联查每一笔订单的详情和进度。把不同订单状态的数据独立出来，方便市场人员对订单进行跟踪，掌握订单状态并及时和客户沟通，让客户和我们有更好的配合、更好地调配货源、更好地管控市场。
                        </p>
                        <br />
                        <p class="spacingStyle">销售汇总表是按品规汇总销售数据，包括退货数据。通过销售汇总数据，客观反映市场动态，找不足，查问题，为下一步的市场销售工作提供指引。</p>
                    </div>
                    <div v-show="showIndex == 7" class="summary">
                        <p class="spacingStyle">
                            库存管理包括待检库管理、入库管理、退货入库管理、退回入库管理、出库管理、其他出库管理、样品领用管理、库存盘点、库存预警共9个模块。库存管理数字化、规范化，确保库存和开单数据的准确性。通过库存数据的分析，还可指导销售部门及时调整销售政策，做好销售工作。
                        </p>
                        <br />
                        <p class="spacingStyle">
                            安全库存是库存管理的目标，通过库存预警实现采购、生产、检验、销售等环节的有序衔接，确保每个品规产品的库存安全。近效期预警提示、库存上限和库存下限预警提示，有效避免近效期、断货、滞销现象的发生。以往很多企业有安全库存概念，却很难做到安全库存，究其原因就是缺少库存预警机制或没把库存预警工作做精做细；有了库存预警，相信能很好地解决这类问题。
                        </p>
                        <br />
                        <p class="spacingStyle">为确保库存数据的严肃行、准确性，系统对入库和出库数据的纠正编辑实行双人复核验证管理，纠正编辑库存数据需要部门负责人授权的短信验证码才行。防止恶意篡改数据。</p>
                    </div>
                    <div v-show="showIndex == 8" class="summary">
                        <p class="spacingStyle">综合资讯包含政策资讯、产品文宣、学习园地和企业文宣4个模块。目的是服务销售，助力市场人员综合素质的提升。</p>
                        <br />
                        <p class="spacingStyle">医药行业的政策法规日新月异，医药市场受国家政策、行业法规的影响较大，有浓郁的政策市场气息。学习政策法规做一个懂法规、用法规、守法规的市场营销人员。</p>
                        <br />
                        <p class="spacingStyle">产品文宣是产品推广的主要媒介，文宣资料数字化是数字传媒的客观要求，大大提高传播速度和传播效率，同时也节约成本。</p>
                        <br />
                        <p class="spacingStyle">学习园地可收集医药营销、医药知识、市场人员继续再教育等方面的学习资料，通过学习提高我们的职业素质，提升我们的竞争力。关注学习园地，坚持学习，日积月累，当刮目相看，这是我们设置学习园地的初衷。</p>
                        <br />
                        <p class="spacingStyle">企业文宣主要收集企业文化宣传方面的视频、PPT、彩页等文宣资料，通过数字化宣传以展示企业文化，促进企业文化的宣传和建设，提高企业的凝聚力和知名度。</p>
                        <br />
                        <p class="spacingStyle">移动端提供的文件管理器，支持音频、视频、图片、WORD等文件的上传和下载，满足日常工作需求。</p>
                    </div>
                    <div v-show="showIndex == 9" class="summary">
                        <p class="spacingStyle">
                            组织架构属系统底层逻辑架构之一，也是每家企业流程运转、部门设置及职能规划的基础和依据。组织架构数字化颠覆传统纸质的或书面的组织架构，彻底解决组织架构落实执行难问题，使组织内的每个部门、每个人相互之间达成逻辑上的上下级关系或平级关系，由于是数字化的组织架构，不存在落实执行问题，只有百分百的执行。
                        </p>
                        <br />
                        <p class="spacingStyle">执行公司领导或公司文件的决定，可自由灵活搭建数字化组织架构，并根据企业的实际情况增加层级关系，层级数量不设限，满足精细化管理的需求。</p>
                        <br />
                        <p class="spacingStyle">员工信息是税务合规模块之一，核查费用报销人员是否是公司在职员工。也是人事档案的重要组成部分。</p>
                    </div>
                    <div v-show="showIndex == 10" class="summary">
                        <p class="spacingStyle">系统设置是系统的核心基础模块。包含药品设置、员工权限、主推产品、宣传窗口、数据安全、菜单管理和我的资料共7个模块。</p>
                        <br />
                        <p class="spacingStyle">
                            员工权限是每位新员工都要依据岗位职责分配相应的权限，否则无法开展工作，包括菜单权限、区域权限和药品权限。菜单权限分的比较细，有227个权限，分到每位员工大概是几十个权限。区域权限严格遵循线下市场的地理区划，可细化到地级市。药品权限是市场管控的手段之一，结合区域权限基本能达到控销的目的。
                        </p>
                        <br />
                        <p class="spacingStyle">
                            考虑到大家接受新生事物需要一个过程，可能会担心数据安全方面的问题，为此我们设计了数据安全设置。数据安全设置包括本地和云服务器双重备份，可手动备份至电脑或云服务器，还可设置云服务器自动备份，确保数据安全。系统提供备份日志和恢复日志，方便查看和选择。系统已经通过
                            IEC27001信息安全管理体系认证。
                        </p>
                    </div>
                    <div v-show="showIndex == 11" class="summary">
                        <p class="spacingStyle">对于OA办公，大家都比较熟悉。相较于传统的OA办公，我们更关注医药工业企业实际需求，如经常用到的样品领用申请、退货申请等，让OA办公更适合医药工业企业的工作需求。</p>
                        <br />
                        <p class="spacingStyle">考虑到企业会在不同城市设立办事处，市场人员分散在全国各地，需要不同的考勤设置；系统可分考勤组设置考勤规则，满足不同部门，不同岗位、不同区域的考勤需求。移动端采用定位加拍照打卡，提高打卡真实率。PC端可选择局域网打卡或电脑 IP地址打卡。</p>
                        <br />
                        <p class="spacingStyle">我的提报是日常工作使用较频繁的功能模块，提供财务、人事、行政、业务和出勤共5大类19种提报模板。包括医药工业企业日常实用的样品领用申请、退货申请等。</p>
                        <br />
                        <p class="spacingStyle">我的审批针对医药行业特点，遵循全过程质量管理原则，设置相应的审批环节，也可根据企业需求定制审批环节，高度贴合客户的实际工作流程。</p>
                    </div>
                    <div v-show="showIndex == 12" class="summary">
                        <p class="spacingStyle">销售档案是药品销售工作的重要组成部分，以往医药工业企业基本没有这个概念，相关销售记录零落在不同部门，不规范也不便查找，针对这个问题，我们设计出数字化销售档案。分订单附件、产品报告、合同归档三部分。</p>
                        <br />
                        <p class="spacingStyle">
                            订单附件自动归集销售清单、税票、相应批次产品报告单、销售合同。可根据客户名称、业务归属、合同编号、区域归属检索订单附件。方便查询，保证能随时随地第一时间给客户提供合同、清单、税票和产品检验报告。同时也为催讨货款甚至走法律途径保存证据。订单附件还对税票是否上传进行统计，分已上传和未上传，方便检查工作遗漏。日常客户要求查询清单、税票和产品检验报告是经常发生的事情，有了订单附件就能彻底解决查询困扰，给客户提供更优质更高效的服务。
                        </p>
                        <br />
                        <p class="spacingStyle">产品报告是产品是否合格的法定证据，是必须提供给客户的资料之一，也是药监部门必查项目。订单完成开单，系统会自动将对应批号的产品报告匹配到订单附件，无需人工操作。</p>
                        <br />
                        <p class="spacingStyle">甲乙双方签字盖章的合同是重要的法律文书，受法律保护。合同归档中已归档、未归档也做了分类和统计，使合同归档工作得到有效管理。做好合同归档是药品销售必不可少的关键环节，也是GMP、GSP规定的必查项目。</p>
                        <br />
                        <p class="spacingStyle">观看视频了解更多。</p>
                    </div>
                    <div v-show="showIndex == 13" class="summary">
                        <p class="spacingStyle">销售计划包括部门计划、公司计划和其他计划，部门计划和公司计划属于考核计划，用于业绩考核；其他计划属于需求计划，服务生产调度。</p>
                        <br />
                        <p class="spacingStyle">
                            部门计划和公司计划一般在年初制定，当计划经上级领导审批通后执行，自动关联相关销售数据，可联查每个计划的完成情况，展示相关销售额和销售量的数据、计划完成情况和智能动态图。部门计划汇总表可用于各种营销会议，具有强大的数据分析功能，这是系统设计销售计划模块的真正目的，是数据分析而不是表象的销售计划。
                        </p>
                        <br />
                        <p class="spacingStyle">其他计划属需求计划，与公司的生产调度有关。其他计划以市场需求为导向，随市场需求变化而相应调整，以满足市场需求。</p>
                        <br />
                        <p class="spacingStyle">观看视频了解更多。</p>
                    </div>
                    <div v-show="showIndex == 14" class="summary">
                        <p class="spacingStyle">客户第一是大家的共识，做好客户管理尤为重要。客户管理涵盖销售业务的每个环节，每个细节，依次设置了客户描述、客户列表、联系人、我的客户、额度管理、客户维护、营销政策、营销服务共8个功能模块。</p>
                        <br />
                        <p class="spacingStyle">客户资源是企业的重要资源，为此系统设置客户资源只能增加，不可删除，使客户资源不会因市场人员变动、离职而丢失，同时新员工也更容易接手。</p>
                        <br />
                        <p class="spacingStyle">
                            为了使市场划分更加精细，精细到每个具体客户；打破传统地理区域划分市场的局限性，在我的客户中创新“业务归属管理”新模式，绑定市场人员和客户的业务归属关系。考虑到有两个或两个以上市场人员同时与同个客户开展业务，系统允许一个客户对应多个“业务归属”的市场人员，并通过药品权限设置进一步明确业务权限。
                        </p>
                        <br />
                        <p class="spacingStyle">信用额度管理、客户维护管理、营销政策管理、营销服务管理，多维度的客户管理助力销售工作做细做精，在数字化营销时代，最大程度提升客户资源的价值。</p>
                    </div>
                    <div v-show="showIndex == 15" class="summary">
                        <p class="spacingStyle">受疫情影响，视频会议加速普及，已成为企业管理的工具，也是职场人员必备技能之一。系统集成腾讯视频会议，使企业拥有自己的视频会议子系统。</p>
                        <br />
                        <p class="spacingStyle">
                            作为企业自己的视频会议系统，系统对发起会议权限做了规范的设置，包括公司会议权限和部门会议权限。可以在手机端发起会议，还可根据会议需求通过手机号码邀请系统外的嘉宾参加，并自动短信提醒。每个人可查看自己参加的所有视频会议，包括待开始、已开始、已结束和已取消的视频会议。待开始视频会议可以上传相关文档，做好会前准备。手机端有视频会议“滚动通知窗口”，每个人只能看到自己的视频会议通知。
                        </p>
                        <br />
                        <p class="spacingStyle">加入会议有三种方式：电话入会、小程序入会和加入会议。建议选择加入会议方式入会，请事先下载腾讯会议APP并登入，这样点击加入就能直接跳转进入腾讯会议室内。</p>
                        <br />
                        <p class="spacingStyle">会议纪要对已结束的视频会议进行整理、提炼、概括和总结；可编辑会议议题、会议结论、问题及任务跟踪和责任人。系统指定由记录人整理会议纪要，在发起会议时就指定记录人。</p>
                        <br />
                        <p class="spacingStyle">历史会议可以查看自己参加的所有已结束会议，包括会议录像、会议文档等。</p>
                    </div>
                    <div v-show="showIndex == 16" class="summary">
                        <p class="spacingStyle">区域人口经济数据是渠道网络的附属部分，通过区域人口经济数据反映区域市场大小，为大家判断区域市场大小提供数据支撑。按地理区域划分标准把全国市场划分为东北区、华北区、华东区、华中区、华南区、西南区和西北区共7大区。</p>
                        <br />
                        <p class="spacingStyle">以大区为单位，展示省、直辖市、地级市（区）、县（县级市）的人口、GDP数据信息。可以编辑最新的人口经济数据，PC端支持模板导入，快速更新数据。</p>
                        <br />
                        <p class="spacingStyle">省、直辖市和大区的人口经济数据是系统逐级“汇总”地级市（区）的数据，这些数据都是官方的；可能有统计上的误差，但不影响市场大小的判断。</p>
                    </div>
                </div>
            </slot>
        </articleIndex>
    </div>
</template>

<script type="text/ecmascript-6">
export default {
    data() {
        return {
            leftList: [
                {
                    text: '系统概述',
                    subtitle: 'SYSTEM OVERVIEW',
                    src: 'https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/gaisu.mp4',
                    poster: 'https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/poster_default.png'
                },
                {
                    text: '渠道网络',
                    subtitle: 'NETWORK CHANNELS',
                    src: 'https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/qudaowangluo.mp4',
                    poster: 'https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/poster_default.png'
                },
                {
                    text: '资料管理',
                    subtitle: 'DATA MANAGEMENT'
                    // src: 'https://fzygsz-1303885703.cos.ap-shenzhen-fsi.myqcloud.com/yunge-official-website/%E8%B5%84%E6%96%99%E7%AE%A1%E7%90%86.mp4',
                    // poster: 'https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/poster_default.png'
                },
                {
                    text: '销售流程',
                    subtitle: 'SALES PROCESS',
                    src: 'https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/xiaoshouliucheng.mp4',
                    poster: 'https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/poster_default.png'
                },
                {
                    text: '物流快递',
                    subtitle: 'LOGISTICS EXPRESS',
                    src: 'https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/wuliukuaidi.mp4',
                    poster: 'https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/poster_default.png'
                },
                {
                    text: '财务管理',
                    subtitle: 'FINANCIAL MANAGEMENT'
                    // src: 'https://fzygsz-1303885703.cos.ap-shenzhen-fsi.myqcloud.com/yunge-official-website/%E8%B4%A2%E5%8A%A1%E7%AE%A1%E7%90%86.mp4',
                    // poster: 'https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/poster_default.png'
                },
                {
                    text: '统计分析',
                    subtitle: 'STATISTIC ANALYSIS'
                    // src: 'https://fzygsz-1303885703.cos.ap-shenzhen-fsi.myqcloud.com/yunge-official-website/%E7%BB%9F%E8%AE%A1%E5%88%86%E6%9E%90.mp4',
                    // poster: 'https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/poster_default.png'
                },
                {
                    text: '库存管理',
                    subtitle: 'INVENTORY MANAGEMENT'
                    // src: 'https://fzygsz-1303885703.cos.ap-shenzhen-fsi.myqcloud.com/yunge-official-website/%E5%BA%93%E5%AD%98%E7%AE%A1%E7%90%86.mp4',
                    // poster: 'https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/poster_default.png'
                },
                {
                    text: '综合资讯',
                    subtitle: 'COMPREHENSIVE INFORMATION'
                    // src: 'https://fzygsz-1303885703.cos.ap-shenzhen-fsi.myqcloud.com/yunge-official-website/%E7%BB%BC%E5%90%88%E8%B5%84%E8%AE%AF.mp4',
                    // poster: 'https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/poster_default.png'
                },
                {
                    text: '组织架构',
                    subtitle: 'ORGANIZATIONAL STRUCTURE'
                    // src: 'https://fzygsz-1303885703.cos.ap-shenzhen-fsi.myqcloud.com/yunge-official-website/%E7%BB%84%E7%BB%87%E6%9E%B6%E6%9E%84.mp4',
                    // poster: 'https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/poster_default.png'
                },
                {
                    text: '系统设置',
                    subtitle: 'SYSTEM SETTINGS'
                    // src: 'https://fzygsz-1303885703.cos.ap-shenzhen-fsi.myqcloud.com/yunge-official-website/%E7%B3%BB%E7%BB%9F%E8%AE%BE%E7%BD%AE.mp4',
                    // poster: 'https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/poster_default.png'
                },
                {
                    text: 'OA办公',
                    subtitle: 'OA ADMINISTRATION'
                    // src: 'https://fzygsz-1303885703.cos.ap-shenzhen-fsi.myqcloud.com/yunge-official-website/OA%E5%8A%9E%E5%85%AC%E6%A8%A1%E5%9D%97.mp4',
                    // poster: 'https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/poster_default.png'
                },
                {
                    text: '销售档案',
                    subtitle: 'SALES RECORDS',
                    src: 'https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/xiaoshoudangan.mp4',
                    poster: 'https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/poster_default.png'
                },
                {
                    text: '销售计划',
                    subtitle: 'SALES PLAN',
                    src: 'https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/xiaoshoujihua.mp4',
                    poster: 'https://fzygsz2022-1303885703.cos.ap-chengdu.myqcloud.com/yunge-official-website/poster_default.png'
                },
                {
                    text: '客户管理',
                    subtitle: 'CUSTOMER MANAGEMENT'
                },
                {
                    text: '视频会议',
                    subtitle: 'VIDEO CONFERENCE'
                },
                {
                    text: '区域人口经济数据',
                    subtitle: 'REGIONAL DEMOGRAPHIC AND ECONOMIC DATA'
                }
            ],
            showIndex: '0'
        }
    },
    created() {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
        this.showIndex = this.$route.query.action ? this.$route.query.action : '0'
    },
    methods: {
        changeIndex(text, index) {
            this.$router.push('/index/decoctionPieces?active=0&action=' + index)
            this.showIndex = String(index)
        },
        goIndex() {
            this.$router.push({
                path: '/index/home'
            })
        }
    },
    watch: {
        $route() {
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
        }
    }
}
</script>

<style lang="less" scoped>
.aboutusBox {
    width: 955px;
    padding: 25px 0 0 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .content_left {
        width: 580px;
    }
    .content_right {
        text-align: center;
        .video_tip {
            color: #646464;
            font-size: 14px;
            margin-bottom: 6px;
        }
    }
    .summary {
        font-size: 14px;
        color: #333333;
    }
}
.free_trial {
    margin: 18px auto;
    width: 154px;
    height: 37px;
    background-color: #1158e8;
    color: #fff;
    font-size: 14px;
    line-height: 37px;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
}
</style>
